import styled from 'styled-components';

export const ContentProfessionalSt = styled.section`
  width: 100%;
  max-width: 400px;
  padding: 20px;

  h3{
    font-size: 28px;
    text-transform: uppercase;
    color: ${(props) => props.theme.content};
    margin-bottom: 10px;
  }

  @media screen and (max-width: 550px) {
    padding: 10px 0;

    h3{
      font-size: 19px;
    }
  }
`;

export const ContentProfessionalBlock = styled.section`
  width: 100%;
  padding: 15px;
  font-size: 20px;
  border-radius: 0.5rem;
  border: 2px solid #282829;

  background: rgba(30, 112, 219, 0.3);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 550px) {
    padding: 5px;
    font-size: 16px;
  }
`;
