import React from 'react';
import { FaRegSnowflake } from 'react-icons/fa';
import { PropTypes } from 'prop-types';
import { ListItemSt } from './style';

export default function ListItem({ text }) {
  return (
    <ListItemSt>
      <FaRegSnowflake />
      <h3>{text}</h3>
    </ListItemSt>
  );
}

ListItem.propTypes = {
  text: PropTypes.string.isRequired,
};
