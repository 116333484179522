import styled from 'styled-components';

export const EbooksSt = styled.section`
  padding: 20px;

  > .center{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  @media screen and (max-width: 550px) {
    padding: 10px 0;
  }
`;

export const EbookContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
