import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { FaRegPlusSquare, FaRegMinusSquare } from 'react-icons/fa';
import { FaqsInfosContent, FaqsInfosSt } from './style';

export default function FaqsInfos({ question, response }) {
  const [show, setShow] = useState(false);

  const HandleShow = () => {
    setShow(!show);
  };

  return (
    <FaqsInfosSt>
      <div>
        <h3>{question}</h3>

        <button type="button" aria-label="handle state" onClick={HandleShow}>
          {show ? <FaRegMinusSquare /> : <FaRegPlusSquare />}
        </button>
      </div>

      {show
        && (
        <FaqsInfosContent>
          <p>{response}</p>
        </FaqsInfosContent>
        )}

    </FaqsInfosSt>
  );
}

FaqsInfos.propTypes = {
  question: PropTypes.string.isRequired,
  response: PropTypes.string.isRequired,
};
