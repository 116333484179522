import styled from 'styled-components';

export const InfosSt = styled.section`
  padding: 30px 0 40px 0;
  background: rgb(0,147,245);
  background: -moz-radial-gradient(circle, rgba(0,147,245,0.4) 0%, rgba(255,255,255,0) 48%);
  background: -webkit-radial-gradient(circle, rgba(0,147,245,0.4) 0%, rgba(255,255,255,0) 48%);
  background: radial-gradient(circle, rgba(0,147,245,0.4) 0%, rgba(255,255,255,0) 48%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0093f5",endColorstr="#ffffff",GradientType=1);

  h2{
    text-transform: inherit;
    color: ${(props) => props.theme.textDark};
    background-color: ${(props) => props.theme.content};
    padding: 8px 20px;
    font-size: 28px;
    text-align: center;
    border-radius: 1.5rem 0 1.5rem 0;

    b{
      color: ${(props) => props.theme.textLigth};
    }
  }

  @media screen and (max-width: 550px){
    padding: 10px 0;
    margin-top: 30px;

    h2{
      font-size: 19px;
    }
  }
`;

export const InfosContent = styled.div`
  width: 100%;
  margin-top: 35px;
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center;
  
  @media screen and (max-width: 550px){
    margin-top: 10px;
  } 
`;

export const InfosContentText = styled.div`
  width: 100%;
  margin-top: 35px;
  display: flex;
  flex-direction: row; 
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @media screen and (max-width: 550px){
    margin-top: 10px;
  }
`;
