import styled from 'styled-components';

export const ListItemSt = styled.div`
  display: flex;
  margin-top: 40px;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  border: 2px solid #2B2B2B;
  border-radius: 0.5rem;
  background: rgba(30, 112, 219, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  
  h3{
    font-size: 23px;
    font-weight: 400;
  }

  svg{
    color: ${(props) => props.theme.content};
    margin-right: 10px;
    font-size: 32px;
  }

  @media screen and (max-width: 550px){
    padding: 10px 15px;
    margin-top: 25px;

    h3{
      font-size: 18px;
    }
    svg{
      font-size: 25px;
      margin-right: 10px;
    }
  }
`;
