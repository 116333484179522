import React from 'react';
import { FooterSt } from './style';

export default function Footer() {
  return (
    <FooterSt>
      <p>Todos os direitos reservados para Mestres do Frio &copy;</p>
    </FooterSt>
  );
}
