import styled from 'styled-components';

export const EbookSt = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin-top: 40px;
  border-radius: 0.5rem;
  padding: 20px 10px 40px 10px;
  background: #0175f5;
  background: linear-gradient(0deg, rgba(1,117,245,0.02) 0%, #0175f5 60%);

  h3{
    width: 80%;
    font-size: 38px;
    text-align: center;
    font-weight: 800;
    text-transform: uppercase;
    color: ${(props) => props.theme.textDark};
  }

  img{
    width: 100%;
    max-width: 450px;
  }

  @media screen and (max-width: 550px){

    h3{
      width: 100%;
      font-size: 21px;
    }

    img{
      max-width: 170px;
      margin-top: 20px;
      transform: scale(1.2);
      position: relative;
      left: -25px;
    }
  }
`;

export const EbookContent = styled.div`
  padding: 20px;
  width: 100%;
  display: flex;

  @media screen and (max-width: 550px){
    padding: 0;
    margin-top: 10px;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
  }
`;

export const EbookText = styled.div`
  width: 35%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  /* margin-top: 20px; */

  h4{
    font-size: 38px;
    font-weight: 800;
  }

  span{
    margin-top: 30px;
    font-size: 33px;
    /* font-weight: 300; */

    b{
      font-weight: 500;
      margin-left: 10px;
      font-size: 70px;
      color: #EA1309;
      text-decoration: line-through;
    }
  }

  p{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    margin-top: 30px;
    font-size: 35px;

    b{
      font-size: 75px;
      color: #00EA2E;
    }
  }

  @media screen and (max-width: 550px){
    width: 100%;
    align-items: flex-start;
    justify-content: center;

    h4{
      font-size: 19px;
    }

    span{
      margin-top: 10px;
      font-size: 19px;

      b{
        font-size: 30px;
      }
    }

    p{
      margin-top: 15px;
      font-size: 19px;

      b{
        font-size: 30px;
      }
    }
  }
`;
