import styled from 'styled-components';

export const ContentTextSt = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 25px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img{
    width: 100%;
    max-width: 200px;
    border-radius: 0.5rem;
    border: 2px solid #2B2B2B;

    /* From https://css.glass */
    background: rgba(30, 112, 219, 0.17);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4.1px);
    -webkit-backdrop-filter: blur(4.1px);
  }

  h3{
    margin-top: 20px;
    font-size: 25px;
    font-weight: 800;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: ${(props) => props.theme.content};
  }

  p{
    font-size: 21px;
    text-align: center;
    margin-top: 5px;
  }

  @media screen and (max-width: 550px){
    padding : 10px 5px;
    margin-top: 20px;

    img{
      max-width: 150px;
    }

    h3{
      margin-top: 10px;
      font-size: 19px;
    }

    p{
      margin-top: 2px;
      font-size: 16px;
    }
  }
`;
