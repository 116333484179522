import React from 'react';
import { SealSt, SealText } from './style';
import Center from '../Center';
import img from '../../assets/selo.png';

export default function Seal() {
  return (
    <SealSt>
      <Center>
        <SealText>
          <img src={img} alt="Imagem Selo" />
          <h2 className="title_section">
            Oferecemos total
            {' '}
            <y>garantia!</y>
          </h2>
          <p>Você tem 7 dias de garantia incondicional! Se por algum motivo você achar que o conteúdo do Mestres do Frio não atende às suas expectativas, basta solicitar o reembolso e seu dinheiro será totalmente devolvido. Satisfação garantida ou seu investimento de volta!</p>
        </SealText>
      </Center>
    </SealSt>
  );
}
