import React from 'react';
import { TitleTopSt } from './style';
import logo from '../../assets/logo.png';

export default function TitleTop() {
  return (
    <TitleTopSt>
      <img src={logo} alt="logo" />
    </TitleTopSt>
  );
}
