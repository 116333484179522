import styled from 'styled-components';

export const ApresentationSt = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  background: rgb(0,147,245);
  background: -moz-radial-gradient(circle, rgba(0,147,245,0.4) 0%, rgba(255,255,255,0) 48%);
  background: -webkit-radial-gradient(circle, rgba(0,147,245,0.4) 0%, rgba(255,255,255,0) 48%);
  background: radial-gradient(circle, rgba(0,147,245,0.4) 0%, rgba(255,255,255,0) 48%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0093f5",endColorstr="#ffffff",GradientType=1);
  
  > .center{
    justify-content: center;
    align-items: center;
  }

  h2{
    text-transform: uppercase;
    width: 100%;
    max-width: 1000px;
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center;

  }

  p{
    font-size: 25px;
    margin-top: 30px;
    font-weight: 400;
    font-style: italic;
  }
  
  .movie{
    border: none;
    width: 100%;
    max-width: 1000px;
    margin-top: 30px;
    height: 450px;
    background-color: grey;
  }

  span{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    font-size: 25px;
    font-weight: 400;
    
    svg{
      font-size: 30px;
      margin-right: 10px;
    }
  }

  a{
    width: 100%;
    max-width: 800px;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1px;
    color: ${(props) => props.theme.textLigth};
    font-weight: 700;
    padding: 16px 0;
    margin-top: 40px;
    border-radius: 0.5rem;
    font-size: 24px;
    text-decoration: none;
    background: ${(props) => props.theme.content};
    background: linear-gradient(90deg, rgba(0,147,245,1) 58%, rgba(1,117,245,1) 77%);
  }

  @media screen and (max-width: 550px){

    margin-top: 20px;

    .movie{
      height: 250px;
    }

    h2{
      font-size: 19px;
    }

    p, span{
      font-size: 16px;
      text-align: center;
      
      svg{
        font-size: 20px;
        margin-right: 2px;
      }
    }

    a{
      width: 100%;
      font-size: 16px;
    }
  }
`;
