import styled from 'styled-components';

export const FaqsInfosSt = styled.div`
  width: 100%;
  border-radius: 0.5rem;
  margin-top: 20px;
  background: rgba(30, 112, 219, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  padding: 10px 25px;

  > div{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h3{
    font-size: 22px;
    font-weight: 500;
  }

  button{
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg{
    cursor: pointer;
    color: ${(props) => props.theme.content};
    font-size: 22px;
  }

  @media screen and (max-width: 550px) {
    /* margin-top: 15px; */
    padding: 10px 15px;

    h3{
      font-size: 19px;
    }

    svg{
      font-size: 19px;
    }
  }
`;

export const FaqsInfosContent = styled.div`
  width: 100%;
  margin-top: 20px;
  padding-bottom: 10px;
  line-height: 30px;

  p{
    text-align: left;
    font-weight: 400;
    width: 100%;
    
  }

  @media screen and (max-width: 550px) {
    line-height: 20px;
    margin-top: 5px;

    p{
      font-size: 16px;
    }
  }
`;
