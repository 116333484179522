import React from 'react';
import Center from '../Center';
import { ListContent, ListSt } from './style';
import ListItem from '../ListItem';

export default function List() {
  const list = [
    { text: '100% objetivo' },
    { text: 'Acesso vitalício' },
    { text: 'Estratégias de marketing' },
    { text: 'Conhecimento Especializado' },
    { text: 'Fidelização de Clientes' },
    { text: 'Diversificação de Serviços' },
    { text: 'Otimização de Processos' },
  ];

  return (
    <ListSt>
      <Center>
        <h2 className="title_section">
          Os
          {' '}
          <x>benefícios</x>
          {' '}
          de adquirir o método
          {' '}
          <x>mestres do Frio</x>
        </h2>

        <ListContent>
          {list.map((element) => (
            <ListItem text={element.text} />
          ))}
        </ListContent>

        <h3>Clique no botão abaixo e tenha acesso ao método que vai te fazer faturar mais e ter resultados extraordinários:</h3>

        <a href="https://payment.ticto.app/O7E85ED26" target="_blank" rel="noreferrer">Quero ter acesso ao método</a>
      </Center>
    </ListSt>
  );
}
