import styled from 'styled-components';

export const BonusSt = styled.section`
  /* min-height: 700px; */
  display: flex;
  align-items: flex-start;
  /* justify-content: space-between; */
  width: 100%;
  max-width: 800px;
  margin-top: 40px;
  border-radius: 0.5rem;
  padding: 20px 10px 40px 10px;
  background: #0175f5;
  background: linear-gradient(0deg, rgba(1,117,245,0.02) 0%, #0175f5 60%);

  img{
    width: 100%;
    max-width: 400px;

  }

  @media screen and (max-width: 550px){
    align-items: center;
    /* flex-direction: column; */

    img{
      max-width: 170px;
      margin-top: 20px;
      transform: scale(1.2);
      position: relative;
      left: -25px;
    }
  }
`;

export const BonusText = styled.div`
  width: 50%;

  h3{
    font-weight: 800;
    font-size: 48px;
    text-transform: uppercase;
    color: ${(props) => props.theme.textDark};
  }

  ul{
    list-style: none;
    margin-top: 25px;
    
    li{
      font-weight: 400;
      font-size: 28px;
      margin: 5px 0;
    }
  }

  @media screen and (max-width: 550px){
    h3{
      font-size: 21px;
    }

    ul{
      margin-top: 10px;
      
      li{
        font-size: 16px;
        margin: 10px 0;
      }
    }
  }
`;
