import React from 'react';
import { InfosContentText, InfosSt } from '../Infos/style';
import Center from '../Center';
import ContentText from '../ContentText';
import img1 from '../../assets/2.png';
import img2 from '../../assets/3.png';
import img3 from '../../assets/4.png';
import img4 from '../../assets/5.png';
import img5 from '../../assets/6.png';

export default function Indicated() {
  const contentInfos = [
    { source: img1, title: 'Destaque', text: 'Você irá tornar-se um profissional acima da média e descobrir os segredos para se destacar' },

    { source: img2, title: 'Faturamento', text: 'Você terá acesso a um conteúdo que demonstra como aumentar seus ganhos' },

    { source: img3, title: 'Conhecimento', text: 'Esse método abrange conhecimentos técnicos e aprofundados para você desfrutar e aplicar no dia-a-dia' },

    { source: img4, title: 'Revelância', text: 'Você terá acesso a um conteúdo rico e exclusivo, que tornará você diferente entre os demais concorrentes' },

    { source: img5, title: 'Estratégia', text: 'No método, você terá conhecimento de estratégias avançadas para aumentar suas vendas e clientes' },
  ];

  return (
    <InfosSt>
      <Center>
        <h2 className="title_section">
          O que exatamente você vai ganhar com o método
          {' '}
          <b>MESTRES DO FRIO</b>
        </h2>

        <InfosContentText>
          {
            contentInfos.map((element) => (
              <ContentText img={element.source} title={element.title} text={element.text} />
            ))
          }
        </InfosContentText>
      </Center>
    </InfosSt>
  );
}
