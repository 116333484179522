import styled from 'styled-components';

export const SealSt = styled.section`

  margin-bottom: 25px;

  > .center{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  @media screen and (max-width: 550px){
    > .center{
      flex-direction: column;
    }

    margin-bottom: 0px;
  }
`;

export const SealText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p{
    font-size: 20px;
    text-align: center;
    margin: 10px 0;
  }

  img{
    width: 100%;
    max-width: 450px;
  }

  @media screen and (max-width: 550px){
    width: 100%;
    margin-top: 20px;

    img{
      width: 80%;
      /* max-width: 400px; */
      margin-bottom: -10px;
    }

    p{
      margin: 5px 0;
      font-size: 16px;
    }
  }
`;
