import React from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from './GlobalStyles';
import TitleTop from './components/TilteTop';
import { DefaultColors } from './style/theme';
import Apresentation from './components/Apresentation';
import Social from './components/Social';
import Infos from './components/Infos';
import List from './components/List';
import Indicated from './components/Indicated';
import Seal from './components/Seal';
import ButtonBuy from './components/ButtonBuy';
import Faqs from './components/Faqs';
import Footer from './components/Footer';
import Proof from './components/Proof';
import Customer from './components/Customer';
import Maps from './components/Maps';
import Professional from './components/Professional';
import Ebooks from './components/Ebooks';

function App() {
  return (
    <ThemeProvider theme={DefaultColors}>
      <GlobalStyles />

      <TitleTop />
      <Apresentation />
      <Social />
      <Infos />
      <Indicated />
      <Proof />
      <List />
      <Customer />
      <Maps />
      <Professional />
      <Ebooks />

      <ButtonBuy />
      <Seal />
      <Faqs />
      <Footer />

    </ThemeProvider>
  );
}

export default App;
