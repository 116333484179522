import React from 'react';
import { PropTypes } from 'prop-types';
import { EbookContent, EbookSt, EbookText } from './style';

export default function Ebook({ img, title, text }) {
  return (
    <EbookSt>
      <h3>{title}</h3>

      <EbookContent>
        <EbookText>

          <h4>Vendido separadamente por: </h4>

          <span>
            R$
            {' '}
            <b>{text}</b>
          </span>

          <p>
            Hoje você leva
            {' '}
            <b>Grátis</b>
          </p>
        </EbookText>

        <img src={img} alt={title} />
      </EbookContent>
    </EbookSt>
  );
}

Ebook.propTypes = {
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
