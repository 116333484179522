import React from 'react';
import { PropTypes } from 'prop-types';
import { ContentProfessionalBlock, ContentProfessionalSt } from './style';

export default function ContentProfessional({ title, text }) {
  return (
    <ContentProfessionalSt>
      <h3>{title}</h3>

      <ContentProfessionalBlock>
        <p>{text}</p>
      </ContentProfessionalBlock>
    </ContentProfessionalSt>
  );
}

ContentProfessional.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
