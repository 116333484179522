import React from 'react';
import { PropTypes } from 'prop-types';
import { EbookContent, EbookSt, EbookText } from '../Ebook/style';

export default function EbookTextC({ img, title, text }) {
  return (
    <EbookSt>
      <h3>{title}</h3>

      <EbookContent>
        <EbookText>

          <span>{text}</span>
        </EbookText>

        <img src={img} alt={title} />
      </EbookContent>
    </EbookSt>
  );
}

EbookTextC.propTypes = {
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
