import styled from 'styled-components';

export const CustomeSt = styled.div`
  width: 100%;
  margin: 10px;
  max-width: 370px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .perfil{
    width: 100%;
    max-width: 150px;
    height: 150px;
    border-radius: 100%;
    object-fit: cover;
  }

  .star{
    margin-top: 20px;
    width: 100%;
    max-width: 200px;
  }

  h3{
    font-size: 26px;
    font-weight: 800;
    color: #F8BC06;
    margin: 15px 0;
  }

  p{
    width: 100%;
    text-align: center;
    font-size: 22px;
  }

  @media screen and (max-width: 550px) {
    margin: 20px 5px 10px 5px;

    .perfil{
      max-width: 140px;
      height: 140px;
    }

    .star{
      margin: 10px 0 20px 0;
      max-width: 150px;
    }

    h3{
      font-size: 24px;
      margin: 10px 0;
    }

    p{
      font-size: 19px;
    }
  }
`;
