import styled from 'styled-components';

export const ProofSt = styled.section`
  > .center{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  margin-top: 30px;

`;

export const ProofNegative = styled.div`
  width: 100%;
  max-width: 800px;
  margin-bottom: 40px;
  padding: 30px;
  border-radius: 0.5rem;
  border: 2px solid #2B2B2B;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: rgba(30, 112, 219, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);

  h2{
    width: 100%;
    font-size: 32px;
    text-transform: uppercase;
    text-align: left;
  }

  > div{
    width: 100%;
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  p{
    width: 100%;
    font-size: 25px;
  }

  svg{
    /* width: 150px; */
    font-size: 40px;
    margin-right: 15px;
  }

  @media screen and (max-width: 550px){
    padding: 15px;
    margin-bottom: 10px;

    h2{
      font-size: 19px;
      text-align: center;
    }

    > div{
      margin-top: 15px;
    }

    p{
      font-size: 16px;
    }

    svg{
      font-size: 20px;
    }
  }

`;

export const ProofPossitive = styled.div`
  width: 100%;
  max-width: 800px;
  margin-bottom: 40px;
  padding: 30px;
  border-radius: 0.5rem;
  border: 2px solid #2B2B2B;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: rgba(30, 112, 219, 1);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);

  h2{
    width: 100%;
    font-size: 32px;
    text-transform: uppercase;
    text-align: left;
  }

  > div{
    width: 100%;
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  p{
    width: 100%;
    font-size: 25px;
  }

  svg{
    /* width: 150px; */
    font-size: 40px;
    margin-right: 15px;
  }

  @media screen and (max-width: 550px){
    padding: 15px;
    margin-top: 30px;
    margin-bottom: 0px;

    h2{
      font-size: 19px;
      text-align: center;
    }

    > div{
      margin-top: 15px;
    }

    p{
      font-size: 16px;
    }

    svg{
      font-size: 20px;
    }
  }

`;
