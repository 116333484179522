import React from 'react';
import { PropTypes } from 'prop-types';
import { GiCheckMark } from 'react-icons/gi';
import { ContentImgSt } from './style';

export default function ContentImg({ title, text }) {
  return (
    <ContentImgSt>
      <h3>
        <GiCheckMark />
        {title}
      </h3>
      <p>{text}</p>
    </ContentImgSt>
  );
}

ContentImg.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};

ContentImg.defaultProps = {
  title: 'Lorem ipsum dolor',
  text: 'consectetur adipiscing elit. Donec eget ligula augue. Quisque imperdiet aliquet sapien, eget suscipit mi ultricies a. Sed nec mi at arcu dictum fermentum.',
};
