import React from 'react';
import { MapsSt } from './style';
import Center from '../Center';
import img from '../../assets/refrigeracao.png';

export default function Maps() {
  const { innerWidth: width } = window;

  return (
    <MapsSt>
      <Center>
        <h2>
          Sua trajetória para prosperar com
          {width < 550
            ? <br /> : <n />}
          {' '}
          Climatização e Refrigeração!
        </h2>

        <img src={img} alt="foto de localização" />

        <a href="https://payment.ticto.app/O7E85ED26" target="_blank" rel="noreferrer">Quero ter acesso ao método</a>
      </Center>
    </MapsSt>
  );
}
