import React from 'react';
import { GiCheckMark } from 'react-icons/gi';
import Center from '../Center';
import { ButtonBuySt, BuyBtn } from './style';

export default function ButtonBuy() {
  return (
    <ButtonBuySt>
      <Center>
        <BuyBtn>
          <div>
            <h2>Adquira hoje, ganhe tudo isso</h2>
          </div>

          <ul>
            <li>
              <GiCheckMark />
              Acesso Vitalício
            </li>
            <li>
              <GiCheckMark />
              Acesso aos bônus
            </li>
            <li>
              <GiCheckMark />
              Acesso a todas as atualizações
            </li>
          </ul>

          <h4>
            Por apenas 09x de
          </h4>
          <h1>R$ 6,15</h1>

          <span>
            ou
            {' '}
            <h6>R$ 47,90</h6>
            {' '}
            à vista
          </span>

          <a href="https://payment.ticto.app/O7E85ED26" target="_blank" rel="noreferrer">Quero ter acesso ao método</a>
        </BuyBtn>
      </Center>
    </ButtonBuySt>
  );
}
