import React from 'react';
import { MdVolumeUp } from 'react-icons/md';
import { ApresentationSt } from './style';

import Center from '../Center';

export default function 
Apresentation() {
  const { innerWidth: width } = window;

  return (
    <ApresentationSt>
      <Center>
        <h2>
          O MÉTODO PARA VOCÊ
          {width < 550
            ? <br /> : <n />}
          {' '}
          <y>FATURAR</y>
          {' '}
          ALTO NO MERCADO DE
          {' '}
          <x>CLIMATIZAÇÃO</x>
          {' '}
          E
          {' '}
          <x>REFRIGERAÇÃO</x>
          ,
          {width < 550
            ? <br /> : <n />}
          {' '}
          O
          {' '}
          <y>ANO INTEIRO</y>
          !
        </h2>

        <p>Segredos que faram você ter aumento imediato de clientes e faturamento.</p>

        <iframe className="movie" width="560" height="315" src="https://www.youtube.com/embed/UaR-pd3UC2U?si=lVO7iVsrdrV6vCTI" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen />

        <span>
          <MdVolumeUp />
          {' '}
          Verifique se o som está ligado!
        </span>

        <a href="https://payment.ticto.app/O7E85ED26" target="_blank" rel="noreferrer">CLIQUE AQUI E COMECE A FATURAR TODOS OS DIAS</a>
      </Center>

    </ApresentationSt>
  );
}
