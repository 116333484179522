import React from 'react';
import { PropTypes } from 'prop-types';
import { CustomeSt } from './style';
import start from '../../assets/stars.png';

export default function Custome({ title, text, img }) {
  return (
    <CustomeSt>
      <img src={img} alt="cliente" className="perfil" />
      <h3>{title}</h3>
      <p>
        &#34;
        {text}
        &#34;
      </p>
      <img src={start} alt="estrelas" className="star" />
    </CustomeSt>
  );
}

Custome.propTypes = {
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
