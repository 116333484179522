import React from 'react';
import { CustomerContent, CustomersSt } from './style';
import Center from '../Center';
import Custome from '../Custome';
import img1 from '../../assets/pessoal1.png';
import img2 from '../../assets/pessoal2.png';
import img3 from '../../assets/pessoal3.png';

export default function Customer() {
  return (
    <CustomersSt>
      <Center>
        <h2>Casos de pessoas que obtiveram sucesso!</h2>

        <CustomerContent>
          <Custome title="Marcos Lima" text="Já estou prosperando com Climatização e Refrigeração, Método mestres do frio me fez a aprender escalar o meu negócio, hoje sou eternamente grato!" img={img1} />

          <Custome title="Félix junior" text="Desde que iniciei o método, Mestres do Frio, aprendi muito no setor de Refrigeração,eu era iniciante e com muitas incertezas, hoje estou com faturamento que jamais achei que iria ter, muito obrigado!" img={img2} />

          <Custome title="Fabrício Oliveira" text="Trabalho com Climatização a mais de 8 anos, e resolvi testar o método Mestres do frio, dobrei o faturamento e hoje sou grato" img={img3} />
        </CustomerContent>
      </Center>
    </CustomersSt>
  );
}
