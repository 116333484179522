import styled from 'styled-components';

export const CustomersSt = styled.section`
background: rgb(0,147,245);
  background: -moz-radial-gradient(circle, rgba(0,147,245,0.4) 0%, rgba(255,255,255,0) 48%);
  background: -webkit-radial-gradient(circle, rgba(0,147,245,0.4) 0%, rgba(255,255,255,0) 48%);
  background: radial-gradient(circle, rgba(0,147,245,0.4) 0%, rgba(255,255,255,0) 48%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0093f5",endColorstr="#ffffff",GradientType=1);

  .center{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  h2{
    font-size: 28px;
    font-weight: 600;
    color: #F8BC06;
    text-transform: uppercase;
    text-align: center;
  }

  @media screen and (max-width: 550px) {
    margin-top: 30px;

    h2{
      font-size: 19px;
    }
  }
`;

export const CustomerContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  margin-top: 30px;

  @media screen and (max-width: 550px) {
    margin-top: 10px;
  }
`;
