import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    *{
      padding: 0;
      margin: 0;
      box-sizing: border-box;
    }

    html,body,#root{
      height: 100vh;
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
    }

    body{
      background-color: ${(props) => props.theme.background};
      color: ${(props) => props.theme.textLigth};
      font-family: 'Roboto', sans-serif;
    }

    .title_section{
      font-size: 28px;
      font-weight: 600;
      letter-spacing: 1px;
      text-align: center;
      text-transform: uppercase;

      @media screen and (max-width: 550px){
        font-size: 19px;
      }
    }


    x{
      color: #0093F5;
    }

    y{
      color: #F8BC06;
    } 

`;

export default GlobalStyles;
