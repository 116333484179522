import styled from 'styled-components';

export const ProfessionalSt = styled.section`
  padding: 20px;
  position: relative;

  > .center{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img{
    /* top: -30px; */
    box-shadow: 1px ;
    position: relative;
    width: 80%;
    background: rgb(0,147,245);
    background: radial-gradient(circle, rgba(0,147,245,1) 0%, rgba(255,255,255,0) 50%);
  }

  @media screen and (max-width: 550px){
    padding: 5px 0;
    margin-top: 30px;

    img{
      transform: scale(1.7);
      width: 100%;
      margin: 70px 0;
      top: -10px;
      /* height: 300px; */
    }
  }
`;

export const ProfessionalContent = styled.div`
  width: 100%;
  margin: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (max-width: 550px){
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
`;
