import React from 'react';
import Center from '../Center';
import { FaqsSt } from './style';
import FaqsInfos from '../FaqsInfos';

export default function Faqs() {
  const faqs = [
    { question: 'O que é o mestres do frio?', response: 'Mestres do frio é um estudo e pesquisa voltado as tendências do mercado e estratégias de vendas nos setores de climatização e refrigeração, com todo o material disponível na palma de sua mão, em formato de e-book.' },
    { question: 'Quais as formas de pagamento?', response: 'O pagamento pode ser realizado através de PIX, cartão de débito e crédito, em até 2x' },
    { question: 'Tem garantia?', response: 'Sim! Nós queremos garantir ao máximo a satisfação. Por conta disso, daremos 7 dias para você decidir se vale a pena ou não. Caso decida que não vale a pena para você, basta você entrar em contato dentro do prazo de 7 dias, e devolvemos 100% do valor investido – sem burocracias ou dificuldades.' },
    { question: 'Quanto tempo de acesso?', response: 'Após a compra do e-book Mestres do Frio, você terá acesso vitalício à todo o conteúdo e material disponibilizado.' },
  ];

  return (
    <FaqsSt>
      <Center>
        <h2 className="title_section">
          <x>Dúvidas</x>
          {' '}
          frequentes
        </h2>

        {
          faqs.map((element) => (
            <FaqsInfos question={element.question} response={element.response} />
          ))
        }

        <span>
          Este produto não garante a obtenção de resultados. Qualquer referência ao desempenho de uma estratégia não deve ser interpretada como uma garantia de resultados.Este site não é afiliado ao Facebook ou a qualquer entidade do Facebook. Depois que você sair do Facebook, a responsabilidade não é deles e sim do nosso site. Fazemos todos os esforços para indicar claramente e mostrar todas as provas do produto e usamos resultados reais. Nós não vendemos o seu e-mail ou qualquer informação para terceiros. Jamais fazemos nenhum tipo de spam. Se você tiver alguma dúvida, sinta-se à vontade para usar o link de contato e falar conosco em horário comercial de Segunda a Sextas das 09h00 ás 18h00. Lemos e respondemos todas as mensagens por ordem de chegada. e-mail;
          {' '}
          <b>mestresdofrio@gmail.com</b>
        </span>

      </Center>
    </FaqsSt>
  );
}
