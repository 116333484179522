import React from 'react';
import Center from '../Center';
import { InfosContent, InfosSt } from './style';
import ContentImg from '../ContentImg';

export default function Infos() {
  const contentInfos = [
    { title: 'Falta de Conhecimento Prévio:', text: 'O método é projetado para atender a todos os níveis de habilidade, desde iniciantes até profissionais experientes, oferecendo uma ampla gama de informações para todos.' },

    { title: 'Tempo Limitado:', text: 'Nosso método é flexível, permitindo que você aprenda no seu próprio ritmo. Você pode acessar o conteúdo quando for mais conveniente para você.' },

    { title: 'Custo do método:', text: 'Considere o investimento como uma valorização profissional. O retorno em termos de habilidades aprimoradas e oportunidades de carreira pode superar em questões de dias.' },

    { title: 'Dúvidas sobre a Qualidade:', text: 'Oferecemos suporte para todos, e garantia do método junto com, histórias de sucesso.' },

    { title: 'Quanto tempo terei o método:', text: 'Nosso método é 100% vitalício, após adquirir ele você poderá acessar quando quiser e pelo tempo que quiser.' },
  ];

  return (
    <InfosSt>
      <Center>
        <h2>
          A jornada com
          {' '}
          <b>MESTRES DO FRIO</b>
          , é o lugar certo, para quem quer faturar mais!
        </h2>

        <InfosContent>
          {
            contentInfos.map((element) => (
              <ContentImg source="#" title={element.title} text={element.text} />
            ))
          }
        </InfosContent>
      </Center>
    </InfosSt>
  );
}
