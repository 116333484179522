import React from 'react';
import Center from '../Center';
import { ProfessionalContent, ProfessionalSt } from './style';
import ContentProfessional from '../ContentProfessional';
import img from '../../assets/homem.png';

export default function Professional() {
  const { innerWidth: width } = window;

  return (
    <ProfessionalSt>
      <Center>
        <h2 className="title_section">
          PARA QUEM É INDICADO
          {width < 550
            ? <br /> : <n />}
          {' '}
          O MÉTODO?

        </h2>

        <ProfessionalContent>
          <ContentProfessional title="Iniciantes" text="O método não só é útil para quem está começando no ramo, ele é essencial! Por que não começar com o pé direito?" />

          <ContentProfessional title="Profissionais" text="O conteúdo do método é completamente aplicável para aqueles que já trabalham na área e buscam conhecimento específico e também alavancar as vendas" />

          <ContentProfessional title="Experts" text="Todo conhecimento é pouco. Mesmo para aqueles que sabem descrever seus trabalhos com perfeição, sempre é possível inovar e ampliar a clientela e estratégias de venda" />
        </ProfessionalContent>

        <img src={img} alt="homem" />
      </Center>
    </ProfessionalSt>
  );
}
