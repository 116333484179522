import styled from 'styled-components';

export const ListSt = styled.section`
  padding: 35px 0;
  background-position: center;
  background-size: cover;
  background: rgb(0,147,245);
  background: -moz-radial-gradient(circle, rgba(0,147,245,0.4) 0%, rgba(255,255,255,0) 48%);
  background: -webkit-radial-gradient(circle, rgba(0,147,245,0.4) 0%, rgba(255,255,255,0) 48%);
  background: radial-gradient(circle, rgba(0,147,245,0.4) 0%, rgba(255,255,255,0) 48%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0093f5",endColorstr="#ffffff",GradientType=1);

  > .center{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .center > h3{
    font-size: 28px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 25px;
  }

  a{
    width: 100%;
    max-width: 800px;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1px;
    color: ${(props) => props.theme.textLigth};
    font-weight: 700;
    padding: 16px 0;
    /* margin-top: 40px; */
    border-radius: 0.5rem;
    font-size: 24px;
    text-decoration: none;
    background: ${(props) => props.theme.content};
    background: linear-gradient(90deg, rgba(0,147,245,1) 58%, rgba(1,117,245,1) 77%);
  }

  @media screen and (max-width: 550px) {
    padding: 20px 0;
    margin-top: 30px;

    .center > h3{
      font-size: 19px;
      margin: 15px 0;
    }

    a{
      font-size: 16px;
    }
  }
`;

export const ListContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;

  @media screen and (max-width: 550px) {
    margin-top: 0px;
    margin: 5px 0 25px 0;
  }
`;
