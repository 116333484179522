import React from 'react';
import { PropTypes } from 'prop-types';
import { BonusSt, BonusText } from './style';

export default function Bonus({
  img, b1, b2, b3, b4, b5,
}) {
  return (
    <BonusSt>
      <BonusText>
        <h3>Bônus</h3>

        <ul>
          <li>{b1}</li>
          <li>{b2}</li>
          <li>{b3}</li>
          <li>{b4}</li>
          <li>{b5}</li>
        </ul>
      </BonusText>

      <img src={img} alt="bônus" />
    </BonusSt>
  );
}

Bonus.propTypes = {
  img: PropTypes.string.isRequired,
  b1: PropTypes.string,
  b2: PropTypes.string,
  b3: PropTypes.string,
  b4: PropTypes.string,
  b5: PropTypes.string,
};

Bonus.defaultProps = {
  b1: '',
  b2: '',
  b3: '',
  b4: '',
  b5: '',
};
