import styled from 'styled-components';

export const FooterSt = styled.section`
  width: 100%;
  background-color: ${(props) => props.theme.content};
  color: ${(props) => props.theme.textLigth};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  margin-top: 50px;
  
  p{
    text-align: center;
  }

  @media screen and (max-width: 550px) {
    margin-top: 20px;
    padding: 15px;
    font-size: 16px;
  }
`;
