import React from 'react';
import { SlDislike, SlLike } from 'react-icons/sl';

import * as styles from './style';

import Center from '../Center';

export default function Proof() {
  const { innerWidth: width } = window;

  return (
    <styles.ProofSt>
      <Center>
        <styles.ProofNegative>
          <h2>
            Sem o método
            {' '}
            {width < 550
              ? <br /> : <n />}
            {' '}
            mestres do frio

          </h2>

          <div>
            <SlDislike color="red" />
            <p>
              Insistem em não investir na carreira profissional, e acabam não tendo o faturamento que desejam, e assim seguem com a vida profissional frustrada.
            </p>
          </div>

          <div>
            <SlDislike color="red" />
            <p>
              Continuam sem prosperar com os ramos que mais crescem no Brasil, e muitas das vezes deixam para procurar investir na última hora, quando já é tarde de mais.
            </p>
          </div>

        </styles.ProofNegative>

        <styles.ProofPossitive>
          <h2>
            Com o método
            {' '}
            {width < 550
              ? <br /> : <n />}
            {' '}
            mestres do frio

          </h2>

          <div>
            <SlLike color="#0EF400" />
            <p>
              Investem tempo e técnicas para converter o máximo de clientes através de estratégias validadas e transformam número de pessoas em clientes fiéis.
            </p>
          </div>

          <div>
            <SlLike color="#0EF400" />
            <p>
              Focam em buscar conhecimento e cada vez mais prosperar no ramo de climatização e Refrigeração.
            </p>
          </div>

        </styles.ProofPossitive>
      </Center>
    </styles.ProofSt>
  );
}
