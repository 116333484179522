import styled from 'styled-components';

export const CenterSt = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1400px;
  padding: 0 5%;
  margin: 0 auto;
`;
