import React from 'react';
import Center from '../Center';
import Ebook from '../Ebook';
import EbookC from '../EbookTextC';
import { EbookContent, EbooksSt } from './style';
import img from '../../assets/mockup_refrigerancao.png';
import img2 from '../../assets/climatizacao.png';
import img3 from '../../assets/segredos.png';
import img4 from '../../assets/segredos2.png';
import img5 from '../../assets/bonus1.png';
import img6 from '../../assets/bonus2.png';
import Bonus from '../Bonus';

export default function Ebooks() {
  return (
    <EbooksSt>
      <Center>
        <h2 className="title_section">O QUE VOCÊ VAI ADQUIRIR AO LEVAR O MÉTODO MESTRES DO FRIO</h2>

        <EbookContent>
          <EbookC title="Método lucrando com refrigeração" text="Descubra as estratégias essenciais para alavancar seu faturamento no mercado de refrigeração com mestres do frio." img={img} />
          <EbookC title="Método lucrando com Climatização" text="Transforme sua jornada no mercado de climatização com mestres do frio. Estratégias comprovadas para impulsionar seus ganhos." img={img2} />
          <Ebook title="Técnicas e segredos da Refrigeração" text="34,90" img={img3} />
          <Ebook title="Técnicas e segredos da Climatização" text="34,90" img={img4} />

          <Bonus img={img5} b1="- Sites valiosos" b2="- Ferramentas necessárias" b3="- Fichas importantes" b4="- Organizador" b5="- E muito mais" />
          <Bonus img={img6} b1="- 05 artes profissionais para Refrigeração" b2="- 05 Artes profissionais para Climatização" b3="- 05 Logotipo Editáveis no Canva" />
        </EbookContent>
      </Center>
    </EbooksSt>
  );
}
