import React from 'react';
import { SocialImg, SocialSt } from './style';
import Center from '../Center';
import img from '../../assets/provasocial-mestresdofrio.png';

export default function Social() {
  return (
    <SocialSt>
      <Center>
        <h2 className="title_section">
          Isso é o que acontece quando você entra para o
          {' '}
          <x>MESTRES DO FRIO!</x>
        </h2>

        <SocialImg>
          <img src={img} alt="prova social" />
        </SocialImg>

        <h2 className="title_section">
          <x>Clique no botão abaixo</x>
          {' '}
          e tenha acesso ao método que vai te fazer
          {' '}
          <x>aumentar o faturamento</x>
          {' '}
          e ter
          {' '}
          <x>resultados extraordinários</x>
        </h2>

        <a href="https://payment.ticto.app/O7E85ED26" target="_blank" rel="noreferrer">Quero ter acesso ao método</a>
      </Center>
    </SocialSt>
  );
}
