import styled from 'styled-components';

export const TitleTopSt = styled.section`
  width: 100%;
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  img{
    width: 100%;
    max-width: 350px;
  }

  @media screen and (max-width: 550px){
    padding: 5px 0;

    img{
      max-width: 200px;
    }
  } 
`;
