import styled from 'styled-components';

export const ButtonBuySt = styled.section`

  margin: 50px 0 20px 0;
  background: rgb(0,147,245);
  background: radial-gradient(circle, rgba(0,147,245,0.2) 0%, rgba(255,255,255,0) 48%);
  .center{
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const BuyBtn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
  border-radius: 1.5rem;
  padding-top: 5px;
  padding-bottom: 30px;
  background: #0175f5;
  background: linear-gradient(0deg, rgba(1,117,245,0.02) 0%, #0175f5 60%);

  > div{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: ${(props) => props.theme.textLigth};
    border-bottom: 2px solid ${(props) => props.theme.textLigth};

    h2{
      text-align: center;
      font-size: 28px;
      text-transform: uppercase;
    }
  }

  ul{
    width: 80%;
    list-style: none;
    margin-top: 30px;

    li{
      display: flex;
      align-items: center;
      font-size: 20px;
      margin: 10px 0;

      svg{
        margin-right: 8px;
      }
    }
  }

  h4{
    letter-spacing: 2px;
    font-size: 20px;
    font-weight: 400;
    margin: 30px 0 10px 0;
  }

  span{
    display: flex;
    align-items: flex-end;
    margin: 20px 0;
    letter-spacing: 1px;
  }

  h1{
    font-size: 85px;
    font-weight: 800;
  }

  h6{
    font-weight: 800;
    font-size: 20px;
    margin: 0 5px;
  }

  a{
    width: 80%;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1px;
    color: ${(props) => props.theme.textLigth};
    font-weight: 700;
    padding: 15px 0;
    margin-top: 10px;
    border-radius: 0.5rem;
    font-size: 22px;
    text-decoration: none;
  
    background: rgb(248,188,6);
    background: linear-gradient(90deg, rgba(248,188,6,1) 46%, rgba(130,98,0,1) 100%);
  }

  @media screen and (max-width: 550px){
    h1{
      font-size: 45px;
    }

    > div{
        padding: 10px;

        h2{
          font-size: 22px;
        }
    }

    ul{
      margin-top: 5px;

      li{
        font-size: 16px;
      }
    }

    h4{
      font-size: 16px;
      margin: 10px 0;
    }

    span{
      font-size: 18px;
      margin: 10px 0;
    }

    h1{
      font-size: 50px;
    }

    h6{
      font-size: 19px;
    }

    a{
      font-size: 16px;
    }
  }
`;
