import styled from 'styled-components';

export const SocialSt = styled.section`
  margin-top: 10px;
  padding: 40px 0;

  > .center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h2{
    font-size: 28px;
    font-weight: 400; 
    text-transform: none;
  }

  .title_section x{
    font-weight: 600 !important; 
  }

  a{
    width: 100%;
    max-width: 800px;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1px;
    color: ${(props) => props.theme.textLigth};
    font-weight: 700;
    padding: 16px 0;
    margin-top: 40px;
    border-radius: 0.5rem;
    font-size: 24px;
    text-decoration: none;
    background: ${(props) => props.theme.content};
    background: linear-gradient(90deg, rgba(0,147,245,1) 58%, rgba(1,117,245,1) 77%);
  }

  @media screen and (max-width: 550px){
    padding: 30px 0;
    margin-top: 30px;

    h2{
      font-size: 19px;
    }

    a{
      font-size: 16px;
    }
  }
`;

export const SocialImg = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 40px 0;



  img{
    width: 100%;
    max-width: 650px;
    height: auto;
    background-position: center;
    background-size: cover;
    object-fit: contain;
  }

  @media screen and (max-width: 550px){
    flex-direction: column;
    margin-top: 10px;

    img{
      margin-top: 10px;
    }
  }
`;
