import React from 'react';
import { PropTypes } from 'prop-types';
import { ContentTextSt } from './style';

export default function ContentText({ img, title, text }) {
  return (
    <ContentTextSt>
      <img src={img} alt={title} />
      <h3>{title}</h3>
      <p>{text}</p>
    </ContentTextSt>
  );
}

ContentText.propTypes = {
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
