import styled from 'styled-components';

export const FaqsSt = styled.section`
  background: rgb(0,147,245);
  background: -moz-radial-gradient(circle, rgba(0,147,245,0.4) 0%, rgba(255,255,255,0) 48%);
  background: -webkit-radial-gradient(circle, rgba(0,147,245,0.4) 0%, rgba(255,255,255,0) 48%);
  background: radial-gradient(circle, rgba(0,147,245,0.4) 0%, rgba(255,255,255,0) 48%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0093f5",endColorstr="#ffffff",GradientType=1);

  h2{
    margin-bottom: 20px;
  }

  span{
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 50px;
    font-size: 20px;
    font-weight: 400;

    b{
      margin-top: 10px;
    }
  }

  @media screen and (max-width: 550px) {

    margin-top: 50px;

    h2{
      margin-bottom: 0px;
    }

    span{
      font-size: 16px;
      margin-top: 50px;
    }
  }
`;
