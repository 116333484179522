import styled from 'styled-components';

export const ContentImgSt = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 10px 0;
  width: 100%;

  h3{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    font-size: 25px;
    font-weight: 600;

    svg{
      color: ${(props) => props.theme.content};
      margin-right: 10px;
    }
  }

  p{
    font-size: 22px;
    margin-top: 5px;
    text-align: left;
  }

  @media screen and (max-width: 550px){
   margin: 5px 0;

   h3{
    font-size: 19px;
    
    svg{
      margin-right: 5px;
    }
   }

   p{
    font-size: 16px;
   }
  }
`;
