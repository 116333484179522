import styled from 'styled-components';

export const MapsSt = styled.section`
  padding: 20px;
  background: rgb(0,147,245);
  background: -moz-radial-gradient(circle, rgba(0,147,245,0.06) 0%, rgba(255,255,255,0) 48%);
  background: -webkit-radial-gradient(circle, rgba(0,147,245,0.06) 0%, rgba(255,255,255,0) 48%);
  background: radial-gradient(circle, rgba(0,147,245,0.06) 0%, rgba(255,255,255,0) 48%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0093f5",endColorstr="#ffffff",GradientType=1);

  > .center{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  h2{
    color: ${(props) => props.theme.textLigth};
    background-color: ${(props) => props.theme.content};
    padding: 10px 20px;
    font-size: 28px;
    text-align: center;
    border-radius: 1.5rem 0 1.5rem 0;

    b{
      color: ${(props) => props.theme.textLigth};
    }
  }

  img{
    width: 100%;
    max-width: 550px;
    margin-top: 30px;
  }

  a{
    width: 100%;
    max-width: 800px;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1px;
    color: ${(props) => props.theme.textLigth};
    font-weight: 700;
    padding: 16px 0;
    /* margin-top: 40px; */
    border-radius: 0.5rem;
    font-size: 24px;
    text-decoration: none;
    background: ${(props) => props.theme.content};
    background: linear-gradient(90deg, rgba(0,147,245,1) 58%, rgba(1,117,245,1) 77%);
  }

  @media screen and (max-width: 550px) {
    padding: 15px 0;
    margin-top: 10px;

    h2{
      white-space: nowrap;
      font-size: 19px;
    }

    img{
      margin-top: 5px;
    }

    a{
      font-size: 16px;
      margin-top: 20px;
    }
  }
`;
